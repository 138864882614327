$NEXT_PUBLIC_CDN_URL: 'https://qa-chicking-cdn.emvigotech.co.uk';
@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.skeleton {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &-shape {
    background: linear-gradient(
      90deg,
      rgba(220, 220, 220, 0.5) 25%,
      rgba(240, 240, 240, 0.5) 50%,
      rgba(220, 220, 220, 0.5) 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;

    &--circle {
      border-radius: 50%;
    }

    &--rectangle {
      border-radius: 0.5rem;
    }

    &--square {
      border-radius: 0;
    }
  }

  &-grid {
    display: grid;
    width: 100%;

    &-child {
      width: 100%;
    }
  }
}
